<template>
  <b-container class="promocode-view">
    <PageHeader
      class="mb-4"
    >
      Промокод
    </PageHeader>

    <!-- Промокод успешно принят -->
    <div
      v-if="!showForm"
    >
      <div class="py-4 text-center">
        <b-icon
          icon="check-circle-fill"
          font-scale="3"
          variant="success"
        />
      </div>

      <div class="text-center">
        Поздравляю, Вы успешно ввели промокод!
      </div>

      <div class="text-center mt-4">
        <b-button
          @click="onShowForm"
          variant="primary"
        >
          Ввести промокод ещё
        </b-button>
      </div>

      <div
        class="text-center mt-4"
      >
        <b-link
          to="/bonuses"
        >
          или просмотреть начисления
        </b-link>
      </div>
    </div>
    <!-- /Промокод успешно принят -->

    <!-- Форма промокод -->
    <b-overlay
        v-else
        :show="isLoading"
        rounded="sm"
        variant="white"
        opacity="0.85"
        spinner-variant="primary"
    >
      <b-form
          @submit="onSubmitForm"
          class="mt-5"
      >
        <!-- Поле Промокод -->
        <b-form-group
            label="Промокод"
            label-for="input-promocode"
            :invalid-feedback="form.promocode.invalidFeedback"
            :state="form.promocode.state"
        >
          <b-form-input
              id="input-promocode"
              v-model="form.promocode.value"
              :state="form.promocode.state"
              trim
              placeholder="Введите сюда промокод"
              @input="changePromoCodeField"
          />
        </b-form-group>
        <!-- /Поле Промокод -->

        <b-button
            type="submit"
            variant="primary"
            block
            :disabled="isLoading || !form.promocode.value ||form.promocode.value.length < 1"
        >
          Использовать промокод
        </b-button>
      </b-form>

      <!-- Ошибка -->
      <b-alert
          show
          v-if="serverErrorMessage"
          variant="danger"
          class="mt-4"
      >{{ serverErrorMessage }}</b-alert>
      <!-- /Ошибка -->
    </b-overlay>
    <!-- Форма промокод -->

  </b-container>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import { usePromocode } from '../api/profi_club/promocode';

export default {
  name: 'Bonuses',

  components: {
    PageHeader,
  },

  data() {
    return {
      isLoading: false,
      serverErrorMessage: null,

      showForm: true,

      form: {
        promocode: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
      },
    };
  },

  methods: {
    async changePromoCodeField(val) {
      this.form.promocode.value = val.toUpperCase();
    },

    async usePromoCodeFromQueryParamsIfExist() {
      if (this.$route.query.promocode) {
        this.form.promocode.value = this.$route.query.promocode;
      } else if (this.$route.query.promo_code) {
        this.form.promocode.value = this.$route.query.promo_code;
      } else if (this.$route.query['promo-code']) {
        this.form.promocode.value = this.$route.query['promo-code'];
      } else if (this.$route.query.PROMOCODE) {
        this.form.promocode.value = this.$route.query.PROMOCODE;
      } else if (this.$route.query.PROMO_CODE) {
        this.form.promocode.value = this.$route.query.PROMO_CODE;
      } else if (this.$route.query['PROMO-CODE']) {
        this.form.promocode.value = this.$route.query['PROMO-CODE'];
      } else if (this.$route.query.promoCode) {
        this.form.promocode.value = this.$route.query.promoCode;
      } else {
        return;
      }

      await this.sendPromocode();
    },

    async onSubmitForm(event) {
      event.preventDefault();

      // Валидация
      if (this.form.promocode.value === '') {
        this.form.promocode.state = false;
        this.form.promocode.invalidFeedback = 'Обязательное поле!';
        return;
      }

      await this.sendPromocode();
    },

    async sendPromocode() {
      this.serverErrorMessage = null;
      this.isLoading = true;
      try {
        await usePromocode(this.form.promocode.value);
        this.isLoading = false;
        this.showForm = false;
      } catch (e) {
        this.isLoading = false;
        this.serverErrorMessage = e.response.data.detail || 'Произошла непредвиденная ошибка!';
      }
    },

    async onShowForm() {
      this.form.promocode = {
        value: '',
        state: null,
        invalidFeedback: null,
      };
      this.showForm = true;
      this.isLoading = false;
    },
  },

  async mounted() {
    await this.usePromoCodeFromQueryParamsIfExist();
  },
};
</script>

<style lang="scss" scoped>
.promocode-view {
  max-width: 460px;
  margin: 0 auto;
  padding: 25px 10px 50px 10px;
  text-align: left;
}
</style>

<template>
  <div
      class="page-header-component"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
  >
    <div
      class="left"
      v-if="!hideBackButton"
    >
      <b-button
          class="pl-0 text-dark"
          variant="link"
          :to="backButtonTo"
          size="lg"
          v-b-tooltip.hover
          title="Назад"
      >
        <b-icon
            icon="arrow-left"
            aria-hidden="true"
        />
      </b-button>
    </div>

    <!-- Заголовок страницы -->
    <div
      class="center"
    >
      <h5
        class="mb-0 font-weight-bold"
      >
        <slot />
      </h5>
    </div>

    <div
      class="right"
    >
      <slot
        name="right"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',

  props: {
    hideBackButton: {
      type: Boolean,
      default: false,
    },
    backButtonTo: {
      type: [String, Object],
      default: '/home',
    },
  },
};
</script>

<style lang="scss">
.page-header-component {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  .left {
    min-width: 36px;
    text-align: left;
  }
  .center {
    width: auto;
    text-align: left;
  }
  .right {
    min-width: 36px;
    text-align: right;
    flex-grow: 1;
  }
}
</style>

import { axiosProfiClubApiWithCredentials } from '../index';

/* Использовать промокод  */
export async function usePromocode(promocode) {
  const url = `promo_code/${promocode}/use`;

  const response = await axiosProfiClubApiWithCredentials.post(url);

  return response.data ? response.data : null;
}
/* /Использовать промокод  */
